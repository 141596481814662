"use client";

import { FirebaseAppProvider } from "reactfire";
import { GA_TRACKING_ID } from "~/utils/gtag";

const firebasePublicConfig = process.env.NEXT_PUBLIC_FIREBASE
  ? JSON.parse(process.env.NEXT_PUBLIC_FIREBASE)
  : undefined;

const firebaseConfig = {
  apiKey: firebasePublicConfig.apiKey,
  authDomain: firebasePublicConfig.authDomain,
  databaseURL: firebasePublicConfig.databaseUrl,
  projectId: firebasePublicConfig.projectId,
  appId: firebasePublicConfig.appId,
  measurementId: GA_TRACKING_ID,
};

export default function FirebaseProvider({ children }: { children: JSX.Element }) {
  return <FirebaseAppProvider firebaseConfig={firebaseConfig}>{children}</FirebaseAppProvider>;
}
