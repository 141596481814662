"use client";

import { useEffect } from "react";
import { api } from "~/trpc/react";
import { auth } from "~/lib/firebase";

export const InvalidateTrpcOnAuthStateChange = () => {
  const utils = api.useUtils();
  const onAuthStateChanged = async () => await utils.invalidate();

  useEffect(() => {
    return auth().onAuthStateChanged(onAuthStateChanged);
  }, [onAuthStateChanged]);

  return null;
};
