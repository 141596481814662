"use client";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { AuthProvider, useFirebaseApp } from "reactfire";
import { getAuthTokens } from "./auth/refreshCustomToken";
import { useEffect } from "react";

export default function FirebaseAuthProvider({
  children,
  refreshAuthToken = true,
}: { children: JSX.Element; refreshAuthToken?: boolean }) {
  const firebaseAuth = getAuth(useFirebaseApp());

  useEffect(() => {
    if (!refreshAuthToken) return;
    const refreshTokenWithCookie = async () => {
      const { customToken } = await getAuthTokens();
      if (customToken) await signInWithCustomToken(firebaseAuth, customToken);
    };
    if (!firebaseAuth.currentUser) refreshTokenWithCookie();
  }, [firebaseAuth, refreshAuthToken]);

  return (
    <AuthProvider sdk={firebaseAuth}>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID as string}>
        {children}
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}
