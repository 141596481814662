"use client";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { customerTheme } from "~/app/(customer)/theme";
import { initI18n } from "~/lib/i18n";

initI18n();

export default function EmotionProvider({ children }: { children: JSX.Element }) {
  return (
    <MantineProvider theme={customerTheme} defaultColorScheme="auto" classNamesPrefix="customer">
      <Notifications position="top-center" autoClose={8000} />
      {children}
    </MantineProvider>
  );
}
